import Municipality from '@/components/views/console/Municipality.vue';
<template>
  <div class="municipality">
    <h3>Administrar Municipios
      <button class="btn btn-primary ml-2"  data-toggle="modal" data-target="#addMunicipality">Agregar municipio</button>
    </h3>
    <div class="form-inline align-items-center">
      <h5>Seleccionar Estado: </h5>
      <select
        class="form-control custom-select-sm mr-2 ml-2"
        id="state"
        v-model="state.selected"
        @change="getMunicipalities(state.selected)"
      >
        <option
          :value="item._id"
          v-for="item, index in state.items"
          :key="index"
        >{{item.name}}</option>
      </select>
      <button class="btn btn-sm btn-outline-danger ml-2" v-bind:disabled="state.selected===''" @click="deleteState(state.selected)">Eliminar estado</button>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th>Estado</th>
            <th>Municipio</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="municipality, index in municipalities" :key="index">
            <td>{{ getStateById(municipality.state) }}</td>
            <td>{{ municipality.name }}</td>
            <td>
              <button
                class="btn btn-danger btn-sm" 
                @click="deleteMunicipality(municipality)"
              >Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="addMunicipality" tabindex="-1" role="dialog" aria-labelledby="addMunicipalityTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addMunicipalityTitle">Agregar municipio</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form  v-on:submit.prevent="saveMunicipality">
            <div class="modal-body">
              <div class="form-inline align-items-center">
                <p class="mt-3">Estado</p>
                <span v-if="!state.isBeingEdited">
                  <select
                    class="form-control custom-select mr-2 ml-2"
                    id="state"
                    v-model="modal.municipality.state"
                    required
                  >
                    <option
                      :value="item._id"
                      v-for="item, index in state.items"
                      :key="index"
                    >{{item.name}}</option>
                  </select>
                  <button type="button" class="btn btn-outline-dark" @click="state.isBeingEdited=true;getStates">Agregar estado</button>
                </span>
                <span v-if="state.isBeingEdited">
                  <input type="text" name="name" autocomplete="off" class="form-control mr-2 ml-2" v-model="state.newState" placeholder="Nuevo estado">
                  <button type="button" class="btn btn-primary mr-2" @click="saveState">Agregar</button>
                  <button type="button" class="btn btn-secondary" @click="state.isBeingEdited=false">Cancelar</button>
                </span>
              </div>
              <div class="form-group mt-2">
                <label for="name">Municipio</label>
                <input type="text" class="form-control" id="name" v-model="modal.municipality.name" autocomplete="off" required>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Municipaliy',
  data () {
    return {
      state: {
        selected: '',
        isBeingEdited: false,
        newState: '',
        items: [],
      },
      modal: {
        municipality: {
          state: '',
          name: '',
        }
      },
      municipalities: [],
    }
  },
  methods: {
    setSafeSelectedItem() {
      if (this.state.items.find(item => item._id === this.state.selected)) {
        this.state.selected = this.state.selected;
      } else {
        this.state.selected = this.state.items[0]._id;
        this.getMunicipalities(this.state.selected);
      }
    },
    saveState () {
      if (this.state.newState.length > 0) {
        this.axios.post('/api/locations/states', {name: this.state.newState}, {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          if (response.data.status === 'error') {
            console.log(response);
            this.$showToast(response.data.message, 'error');
          } else {
            this.$showToast(response.data.message, 'success');
            this.getStates();
          }
        })
        .catch(error => {
          console.log(error);
        });
        this.state.newState = ''
        this.state.isBeingEdited = false
      } else {
        this.$showToast('El nombre del estado no puede estar vacío', 'error')
      }
    },
    getStates () {
      this.axios.get('/api/locations/states', {
        headers: {
          'Authorization': this.$getAuthorization(),
        },
      })
      .then(response => {
        if (response.data.status === 'error') {
          console.log(response);
          this.$showToast(response.data.message, 'error');
        } else {
          this.state.items = response.data.states;
          this.setSafeSelectedItem();
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    getStateById(id) {
      let state = this.state.items.find(item => item._id === id);
      return state.name;
    },
    getMunicipalities (id) {
      this.axios.get(`/api/locations/states/${id}/municipalities`, {
        headers: {
          'Authorization': this.$getAuthorization(),
        },
      })
      .then(response => {
        if (response.data.status === 'error') {
          console.log(response);
          this.$showToast(response.data.message, 'error');
        } else {
          this.municipalities = response.data.municipalities;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    saveMunicipality () {
      if (this.modal.municipality.state.length > 0) {
        this.axios.post(`/api/locations/states/${this.modal.municipality.state}/municipalities`, {name: this.modal.municipality.name}, {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          if (response.data.status === 'error') {
            console.log(response);
            if (response.data.error.code === 11000) {
              this.$showToast(`Estos valores ya existen, intenta con uno diferente\n${Object.entries(response.data.error.keyValue).map(x=>x.join(" = ")).join("\n")}`, 'error');
            } else {
              this.$showToast(response.data.message, 'error');
            }
          } else {
            this.$showToast(response.data.message, 'success');
            this.getMunicipalities(this.state.selected);
            $('#addMunicipality').modal('hide');
          }
        })
        .catch(error => {
          console.log(error);
        });
        this.modal.municipality.name = ''
      } else {
        this.$showToast('Selecciona un estado', 'error')
      }
    },
    deleteState(id) {
      const alert = confirm(`¿Está seguro de eliminar el estado de "${this.getStateById(id)}"?\nSe eliminarán también todos los municipios de este estado`);
      if (alert) {
        this.axios.delete(`/api/locations/states/${id}`, {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          if (response.data.status === 'error') {
            console.log(response);
            this.$showToast(response.data.message, 'error');
          } else if(response.data.status === 'warning') {
            this.$showToast(response.data.message, 'warning');
            this.getStates();
          } else {
            this.$showToast(response.data.message, 'success');
            this.getStates();
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    deleteMunicipality(Municipality) {
      const alert = confirm(`¿Está seguro de eliminar el municipio de "${Municipality.name}"?`);
      if (alert) {
        this.axios.delete(`/api/locations/municipalities/${Municipality._id}`, {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          if (response.data.status === 'error') {
            console.log(response);
            this.$showToast(response.data.message, 'error');
          } else {
            this.$showToast(response.data.message, 'success');
            this.getMunicipalities(this.state.selected);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  },
  mounted() {
    this.getStates();
  },
}
</script>