import Municipality from '@/components/views/console/Municipality.vue';
import Accounts from '@/components/views/console/Accounts.vue';
<template>
  <div class="accounts">
    <h3>Administrar Cuentas
      <button class="btn btn-primary ml-2" @click="openModal('Crear cuenta')">Crear cuenta</button>
    </h3>
    <div class="table-responsive">
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th>Usuario</th>
            <th>Nombre</th>
            <th>Municipio a cargo</th>
            <th>Rol</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="account, index in accounts" :key="index">
            <td>{{ account.username }}</td>
            <td>{{ account.name }}</td>
            <td v-if="account.role<2">---</td>
            <td v-if="account.role>=2">{{ account.municipality }}, {{account.state}}</td>
            <td v-if="account.role===0">Super admin</td>
            <td v-if="account.role===1">Administrador</td>
            <td v-if="account.role===2">Operador</td>
            <td>
              <button
                class="btn btn-primary btn-sm mr-1" 
                @click="openModal('Editar cuenta', account)"
              >Editar</button>
              <button
                v-bind:disabled="account.role===0"
                class="btn btn-danger btn-sm" 
                @click="deleteAccount(account)"
              >Eliminar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="editCreateAccount" tabindex="-1" role="dialog" aria-labelledby="editCreateAccountTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editCreateAccountTitle">{{modalData.title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form  v-on:submit.prevent="saveModal">
            <div class="modal-body">
              <div class="form-group">
                <label for="username">Usuario</label>
                <input type="text" class="form-control" id="username" v-model="modalData.user.username" autocomplete="off" required>
              </div>
              <div class="form-group">
                <label for="name">Nombre</label>
                <input type="text" class="form-control" id="name" v-model="modalData.user.name" autocomplete="off" required>
              </div>
              <div class="form-group">
                <label for="name">Contraseña</label>
                <input type="text" class="form-control" id="name" v-model="modalData.user.password" autocomplete="off" v-bind:required="modalData.user.id===''" :placeholder="modalData.passwordPlaceholder">
              </div>
              <div class="form-group">
                <label for="role">Rol</label>
                <select class="form-control" id="role" v-model="modalData.user.role" required>
                  <option value="0" v-if="modalData.user.role===0">Super admin</option>
                  <option value="1" v-if="modalData.user.role!==0">Administrador</option>
                  <option value="2" v-if="modalData.user.role!==0">Operador</option>
                </select>
              </div>
              <div v-if="modalData.user.role>=2">
                <div class="form-group">
                  <label for="state">Municipio a cargo</label>
                  <div class="form-group row mr-1 ml-1">
                    <select
                      class="form-control custom-select col-md-6 mr-2"
                      id="state"
                      v-model="modalData.user.state"
                      @change="getMunicipalities(modalData.user.state)"
                      required
                    >
                      <option
                        :value="item.name"
                        v-for="item, index in states"
                        :key="index"
                      >{{item.name}}</option>
                    </select>
                    <select
                      class="form-control custom-select col"
                      id="state"
                      v-model="modalData.user.municipality"
                      required
                    >
                      <option
                        :value="item.name"
                        v-for="item, index in municipalities"
                        :key="index"
                      >{{item.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <span>Lugar desde donde se está operando</span>
                  <GmapMap
                    :center="map.center"
                    :zoom="11"
                    :options="map.options"
                    map-type-id="roadmap"
                    style="width: 100%; height: 400px"
                    @click="setMarker($event.latLng)"
                  >
                    <GmapMarker
                      v-for="(m, index) in map.markers"
                      :position="m.position"
                      :clickable="false" :draggable="true"
                      @drag="updatePosition(index, $event.latLng)"
                      :key="index"
                      ></GmapMarker>
                  </GmapMap>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accounts',
  data() {
    return {
      map: {
        options: this.$global.mapOptions,
        center: {lat:19.201209, lng:-98.805840},
        markers: [
          {
            position: {
              lat: 10.0,
              lng: 10.0
            }
          }
        ]
      },
      accounts: [],
      states: [],
      municipalities: [],
      modalData: {
        title: '',
        oldhash: '',
        passwordPlaceholder: '',
        user: {
          id: '',
          username: '',
          password: '',
          name: '',
          role: '',
          latitude: null,
          longitude: null,
          state: null,
          municipality: null,
        },
      }
    }
  },
  methods: {
    setSafeSelectedItem() {
      if (this.states.find(item => item.name === this.modalData.user.state)) {
        this.modalData.user.state = this.modalData.user.state;
      } else {
        this.modalData.user.state = this.states[0].name;
        this.getMunicipalities(this.modalData.user.state);
      }
    },
    openModal(title, account) {
      this.modalData.title = title;
      if (account) {
        this.modalData.oldhash = account.password;
        this.modalData.passwordPlaceholder = 'Si se deja vacío no se modifica la contraseña';
        this.modalData.user = {
          id: account._id,
          username: account.username,
          password: '',
          name: account.name,
          role: account.role,
          latitude: account.latitude,
          longitude: account.longitude,
          state: account.state,
          municipality: account.municipality,
        }
      } else {
        this.modalData.oldhash = '';
        this.modalData.passwordPlaceholder = '';
        this.modalData.user = {
          id: '',
          username: '',
          password: '',
          name: '',
          role: '',
          latitude: null,
          longitude: null,
          state: null,
          municipality: null,
        }
      }
      this.getStates();
      $('#editCreateAccount').modal('show')
    },
    getAccounts() {
      this.axios.get('/api/admins',
        {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          this.accounts = response.data.admins;
        })
        .catch(error => {
          console.log(error);
        });
    },
    deleteAccount(account) {
      if (account.username === localStorage.getItem('username')) {
        this.$showToast('No puedes eliminar tu propia cuenta, pide a otro administrador que lo haga por ti', 'error');
        return;
      }
      const alert = confirm(`¿Está seguro de eliminar la cuenta ${account.username}?`);
      if (alert) {
        this.axios.delete('/api/admins/' + account._id, {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
        .then(response => {
          console.log(response);
          if (response.data.status === 'error') {
            this.$showToast(response.data.message, 'error');
          } else {
            this.$showToast(response.data.message, 'success');
            this.getAccounts();
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    getStates () {
      this.axios.get('/api/locations/states', {
        headers: {
          'Authorization': this.$getAuthorization(),
        },
      })
      .then(response => {
        console.log(response);
        if (response.data.status === 'error') {
          this.$showToast(response.data.message, 'error');
        } else {
          this.states = response.data.states;
          this.setSafeSelectedItem();
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    getMunicipalities (name) {
      const stateId = this.states.find(item => item.name === name)._id;
      this.axios.get(`/api/locations/states/${stateId}/municipalities`, {
        headers: {
          'Authorization': this.$getAuthorization(),
        },
      })
      .then(response => {
        console.log(response);
        if (response.data.status === 'error') {
          this.$showToast(response.data.message, 'error');
        } else {
          this.municipalities = response.data.municipalities;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    saveModal() {
      if (this.modalData.user.password === '') {
        this.modalData.user.password = this.modalData.oldhash;
      } else {
        this.modalData.user.password = 
          this.$CryptoJS.SHA256(this.modalData.user.password).toString();
      }
      // Convierte el nombre de usuario a minusculas y elimina todos los espacios
      this.modalData.user.username = this.modalData.user.username.toLowerCase().replace(/\s/g, '');
      // Si el rol de usuario es igual a 2 o más se guardan también la longitud y latitud
      if (this.modalData.user.role >= 2) {
        this.modalData.user.latitude = this.map.markers[0].position.lat;
        this.modalData.user.longitude = this.map.markers[0].position.lng;
      }
      this.axios.post('/api/admins', this.modalData.user, {
        headers: {
          'Authorization': this.$getAuthorization(),
        },
      })
      .then(response => {
        if (response.data.status === 'error') {
          console.log(response);
          if (response.data.error.code === 11000) {
            this.$showToast(`Estos valores ya existen, intenta con uno diferente\n${Object.entries(response.data.error.keyValue).map(x=>x.join(" = ")).join("\n")}`, 'error');
          } else {
            this.$showToast(response.data.message, 'error');
          }
        } else {
          this.$showToast(response.data.message, 'success');
          this.getAccounts();
          $('#editCreateAccount').modal('hide');
        }
      })
      .catch(error => {
        console.log(error);
      });
      this.modalData.user.password = '';
    },
    geolocate () {
      navigator.geolocation.getCurrentPosition(position => {
        this.map.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        // establecer el marcador del mapa en la localización actual
        this.map.markers[0].position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    setMarker(position) {
      this.map.markers[0].position = {
        lat: position.lat(),
        lng: position.lng(),
      };
    },
    updatePosition(index, position) {
      this.map.markers[index].position = {
        lat: position.lat(),
        lng: position.lng(),
      };
    }
  },
  created() {
    this.getAccounts();
    this.geolocate();
  },
}
</script>