<template>
  <div class="users">
    <h3>Usuarios</h3>
    <div class="table-responsive">
      <table class="table table-hover table-striped">
        <thead>
          <tr>
            <th>Foto</th>
            <th>Nombre</th>
            <th>Edad</th>
            <th>Teléfono</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user, index in users" :key="index">
            <td>
              <img class="profile-image" :src="user.picture" alt="Imagen de perfil">
              <!-- <img class="profile-image" src="https://picsum.photos/200/300" alt="Imagen de perfil"> -->
            </td>
            <td>{{ user.name }} {{user.lastName}}</td>
            <td>{{ user.age }}</td>
            <td>{{ user.phone }}</td>
            <td v-if="!user.isBanned && !user.inVerProcess && user.verified">Verificado</td>
            <td v-if="!user.isBanned && !user.inVerProcess && !user.verified" class="text-danger">Rechazado</td>
            <td v-if="!user.isBanned && user.inVerProcess" class="text-warning">En proceso</td>
            <td v-if="user.isBanned" class="text-danger">Suspendido</td>
            <td>
              <button
                v-if="!user.isBanned && user.inVerProcess"
                class="btn btn-primary btn-sm mr-1" 
                @click="verifyUser(user)"
              >Verificar</button>
              <button
                v-if="!user.isBanned"
                class="btn btn-danger btn-sm" 
                @click="ban(user)"
              >Suspender</button>
              <button
                v-if="user.isBanned"
                class="btn btn-dark btn-sm" 
                @click="unban(user)"
              >Reactivar</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Users',
  data() {
    return {
      users: [],
    }
  },
  methods: {
    getUsers() {
      this.axios.get('/api/users',
        {
          headers: {
            'Authorization': this.$getAuthorization(),
          },
        })
      .then(response => {
        if (response.data.status === 'success') {
          this.users = response.data.users;
        } else {
          console.log(response.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
    verifyUser(user) {
      this.$router.push({
        name: 'user',
        params: {
          user: user,
        },
      });
    },
    ban(user) {
      const reason = prompt('Al suspender un usuario este no podrá enviar nuevas alertas\n\nColoque la razón de la suspensión de la cuenta');
      if (reason) {
        this.axios.post('/api/users/ban/'+user._id,
          {
            reason: reason,
          },
          {
            headers: {
              'Authorization': this.$getAuthorization(),
            },
          })
        .then(response => {
          if (response.data.status === 'success') {
            this.$showToast(response.data.message, 'success');
            this.getUsers();
          } else {
            this.$showToast(response.data.message, 'error');
            console.log(response.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    unban(user) {
      const alert = confirm('¿Está seguro de que desea reactivar la cuenta de este usuario?\nAl reactivar la cuenta este podrá enviar nuevas alertas');
      if (alert) {
        this.axios.post('/api/users/unban/'+user._id,
          {
            headers: {
              'Authorization': this.$getAuthorization(),
            },
          })
        .then(response => {
          if (response.data.status === 'success') {
            this.$showToast(response.data.message, 'success');
            this.getUsers();
          } else {
            this.$showToast(response.data.message, 'error');
            console.log(response.data);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  },
  created() {
    this.getUsers();
  },
}
</script>

<style scoped>
.profile-image {
  height: 50px;
  width: 50px;
  border-radius: 15%;
  object-fit: cover;
}
tr td {
  vertical-align: middle;
}
</style>